import {TPLBase} from "./tpl_base";
import {AjaxPromise} from "../../shared/ajax_utilities";

class Defects extends TPLBase {
    DEFAULT_TAB = 'rep';

    constructor() {
        super('defects');
    }

    async setup() {
        if(!app.OPTIONS.gridstack_allowed || !app.OPTIONS.gridstack_allowed[this.GRID_ID]) {
            console.warn(`Unable to find gridstack options for ${this.GRID_ID} and is unable to setup tpl`);
            return;
        }
        const opts = app.OPTIONS.gridstack_allowed[this.GRID_ID];

        try {
            let tab = app.OPTIONS.gridstack[this.GRID_ID].tab;
            if(tab !== 'rep' && tab !== 'mai') {
                tab = 'rep';
            }

            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX_TPL}${opts.ajax_tpl}`,
                method: 'POST',
                data: {
                    dashboard: app.DASHBOARD_CORE.DASHBOARD,
                    allowed: app.DASHBOARD_CORE.getDataGridstack(),
                    tab: tab,
                }
            });
            this.paintTab(res, tab);
        } catch(err) {
            console.error(`Unable to setup ${this.GRID_ID}`, err);
        }
    }

    paintTab(res, tab) {
        const tabEl = $(`#${this.GRID_ID}`);
        tabEl.find(`#${tab} tbody`).html(res);
        app.TABLE_CORE.parseTables(tabEl.find(`#${tab}`));
        app.DASHBOARD_CORE.populateAdditionalRow(app.DOM.content.find(`#${this.GRID_ID}`));
        const count = tabEl.find(`#${tab} tbody tr`).not('.topup-row').length;
        tabEl.find('#count').text(`(${count})`)
    }

    async tabChange(tabIndex) {
        $(`#${this.GRID_ID}`).attr('gs-tab', tabIndex);
        app.OPTIONS.gridstack[this.GRID_ID].tab = tabIndex;
        app.DASHBOARD_CORE.GRID.engine.nodes = app.DASHBOARD_CORE.GRID.engine.nodes.map((node) => {
            if(node.id !== this.GRID_ID) {
                return node;
            }
            node.tab = tabIndex;
            return node;
        });
        app.DASHBOARD_CORE.GRID.save();
        const opts = app.OPTIONS.gridstack_allowed[this.GRID_ID];
        if(!opts.ajax_tpl) {
            console.warn(`Unable to setup ${this.GRID_ID} tpl`);
            return;
        }

        const res = await AjaxPromise({
            url: `${app.CACHE.URL_AJAX_TPL}${opts.ajax_tpl}`,
            method: 'POST',
            data: {
                dashboard: app.DASHBOARD_CORE.DASHBOARD,
                allowed: app.DASHBOARD_CORE.getDataGridstack(),
                tab: tabIndex,
            }
        });
        this.paintTab(res, tabIndex);
        app.DASHBOARD_CORE.populateAdditionalRow(app.DOM.content.find(`#${this.GRID_ID}`));
    }

    async update() {
        try {
            let tab = app.OPTIONS.gridstack[this.GRID_ID].tab;
            if(tab !== 'rep' && tab !== 'mai') {
                tab = 'rep';
            }

            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX_TPL}${opts.ajax_tpl}`,
                method: 'POST',
                data: {
                    dashboard: app.DASHBOARD_CORE.DASHBOARD,
                    allowed: app.DASHBOARD_CORE.getDataGridstack(),
                    tab: tab,
                }
            });
            this.paintTab(res, tab);
            app.DASHBOARD_CORE.populateAdditionalRow(app.DOM.content.find(`#${this.GRID_ID}`));
        } catch(err) {
            console.log(err);
        }
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new Defects();
    } else {
        console.warn('DashboardCore not initialized.');
    }
});